<template>
  <div id="productAddOnContainer">
    <div>
      <CRow id="productAddOnRow">
        <CCol id="productAddOnOptionCol" sm="6" lg="6" md="6">
          <div id="productAddOnOptionHeaderRow" class="row mt-2">
            <div id="productAddOnOptionHeaderCol" class="col-md-10 col-sm-9">
              <h5 id="productAddOnOptionHeader" class="font-weight-normal mb-3">
                {{ $t('txtOptionCategory') }}
              </h5>
            </div>
          </div>
          <table id="productBOMItemsWrapper" class="table table-hover text-dark">
            <tbody>
              <div class="con-add  " style="overflow-y: auto; ">
                <CRow style=" width: 100%" id="productBOMItemsRow" v-for="(item, index) in optionGroupList"
                  :key="index" >
                  <CCol :id="'productBOMItemsCol' + index" col="12" lg="12">
                    <CCard :id="'productBOMItemsCard' + index" class="addon-body  p-3 aohover"
                    @click="isEditData && onCheckboxChange(item, index)">
                      <CCardBody :id="'productBOMItemsCardBody' + index" style="padding: 0.3rem">
                        <div :id="'productBOMItemsRowData' + index" style="border-color: #ffffff">
                          <CRow :id="'productAddOnOptionGroupContentRow' + index">
                            <CCol :id="'productAddOnOptionGroupImageCol' + index" col="2">
                              <img :id="'productAddOnOptionGroupImage' + index" src="/img/star_icon.png"
                                style="border-radius: 5px;" class="img-fluid" />
                            </CCol>
                            <CCol :id="'productAddOnOptionGroupInfoCol' + index" col="10">
                              <div :id="'productAddOnOptionGroupInfoTr' + index" class="row">
                                <div :id="'productAddOnOptionGroupName' + index" class="col-lg-10">
                                  <h5 style="font-size: medium;" class="text-dark font-weight-normal">{{ item.name }}
                                  </h5>
                                </div>
                                <div :id="'productAddOnOptionGroupAction' + index" class="col-2 text-right text-success">
                                  <img :id="'productAddOnOptionGroupActionIcon' + index" style="width: 20px;"
                                    src="/img/flat-color-icons--plus.png"
                                    v-show="isEditData" />
                                </div>
                              </div>
                              <div :id="'productAddOnOptionGroupDetailsRow' + index" class="row">
                                <div class="col-9 text-dark font-weight-normal"
                                  :id="'productAddOnOptionGroupDetails' + index"
                                  style="width: 70%; margin-bottom: 0rem;">
                                  <CBadge id="productAddOnOptionGroupRequiredBadge" color="success" v-if="item.require">
                                    Required
                                  </CBadge>
                                  <CBadge :id="'productAddOnOptionGroupMinBadge' + index" color="primary"
                                    v-if="item.minimum !== undefined">
                                    Min : <span>{{ item.minimum }}</span>
                                  </CBadge>
                                  <CBadge :id="'productAddOnOptionGroupMaxBadge' + index" color="info"
                                    v-if="item.maximum !== undefined">
                                    Max : <span>{{ item.maximum }}</span>
                                  </CBadge>
                                </div>
                                <div :id="'productAddOnOptionGroupTotals' + index"
                                  class="col-3 text-right text-dark font-weight-normal" style="margin-bottom: 0rem;">
                                  {{ $t('totals') }} ({{ item.items.length }})
                                </div>
                              </div>
                              <hr id="productAddOnOptionGroupDivider" class="hr-card-option" />
                              <div :id="'productAddOnOptionGroupItemsRow' + + index"
                                v-for="(subItem, subIndex) in item.items" :key="subIndex"
                                style="border-top: 0px solid #ececec" class="row">
                                <div class="col-9" :id="'productAddOnOptionGroupItemName' + subIndex" style="width: 90%">
                                  <p class="p-s text-dark font-weight-normal">
                                    <i class="fa-solid fa-circle" style="font-size: .3rem;"></i> {{
                                    subItem.optionItem.name }}
                                  </p>
                                </div>
                                <div :id="'productAddOnOptionGroupItemPrice' + subIndex"
                                  class="col-3 text-right text-dark font-weight-normal">
                                  <p class="p-s">{{ subItem.optionItem.price }}</p>
                                </div>
                              </div>
                            </CCol>
                          </CRow>
                        </div>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </div>
            </tbody>
          </table>

        </CCol>
        <CCol id="productAddOnActiveCol" sm="6" lg="6" md="6" style="background-color: #f6f6f6">
          <div id="productAddOnActiveHeaderRow" class="row mt-2">
            <div id="productAddOnActiveHeaderCol" class="col-md-10 col-sm-9">
              <h5 id="productAddOnActiveHeader" class="font-weight-normal mb-3">
                {{ $t('addOnActive') }}
              </h5>
            </div>
          </div>
          <table class="table table-hover">
            <tbody>
              <div class="con-add " style="overflow-y: auto; ">
                <CRow id="productAddOnActiveGroupRow" v-for="(item, index) in activeGroup" :key="index"
                  style=" width: 100%">
                  <CCol :id="'productAddOnActiveGroupCol' + index" col="12" lg="12">
                    <CCard :id="'productAddOnActiveGroupCard' + index" class="addon-body-active  p-0 aohover">
                      <CCardBody :id="'productAddOnActiveGroupBody' + index">
                        <CRow :id="'productAddOnActiveGroupContentRow' + index">
                          <CCol :id="'productAddOnActiveGroupImageCol' + index" col="2">
                            <img :id="'productAddOnActiveGroupImage' + index" src="/img/star_icon.png"
                              style="border-radius: 5px;" class="img-fluid" />
                          </CCol>
                          <CCol :id="'productAddOnActiveGroupInfoCol' + index" col="10">
                            <div :id="'productAddOnActiveGroupInfoRow' + index" class="row">
                              <div :id="'productAddOnActiveGroupName' + index"
                                class="col-9 text-dark font-weight-normal">
                                <p style="font-size: medium;">{{ item.groupActiveName }}</p>
                              </div>
                              <div :id="'productAddOnActiveGroupAction' + index" class="col-3 text-right text-danger">
                                <img :id="'productAddOnActiveGroupActionIcon' + index" src="/img/product/delete32.png"
                                  style="width: 25px;" v-if="isDeleteData" @click="confirmdeleteOptionGroup(item)"
                                  v-show="isEditData" />
                              </div>
                            </div>
                            <div :id="'productAddOnActiveGroupDetailsRow' + index" class="row">
                              <div :id="'productAddOnActiveGroupDetails' + index"
                                class="col-9 text-dark font-weight-normal" style="width: 70%; margin-bottom: 0rem;">
                                <CBadge :id="'productAddOnActiveGroupRequiredBadge' + index" color="success"
                                  v-if="item.require">
                                  Required
                                </CBadge>
                                <CBadge :id="'productAddOnActiveGroupMinBadge' + index" color="primary"
                                  v-if="item.minimum !== undefined">
                                  Min : <span>{{ item.minimum }}</span>
                                </CBadge>
                                <CBadge :id="'productAddOnActiveGroupMaxBadge' + index" color="info"
                                  v-if="item.maximum !== undefined">
                                  Max : <span>{{ item.maximum }}</span>
                                </CBadge>
                              </div>
                              <div :id="'productAddOnActiveGroupTotals' + index"
                                class="col-3 text-right text-dark font-weight-normal" style="margin-bottom: 0rem;">
                                {{ $t('totals') }} ({{ item.items.length }})
                              </div>
                            </div>
                            <hr id="productAddOnActiveGroupDivider" class="hr-card-option" />
                            <div :id="'productAddOnActiveGroupItemsRow' + index"
                              v-for="(subItem, subIndex) in item.items" :key="subIndex"
                              style="border-top: 0px solid #ececec" class="row text-dark font-weight-normal">
                              <div :id="'productAddOnActiveGroupItemName' + subIndex" style="width: 90%" class="col-9">
                                <p class="p-s">
                                  <i class="fa-solid fa-circle" style="font-size: .3rem;"></i> {{ subItem.name }}
                                </p>
                              </div>
                              <div :id="'productAddOnActiveGroupItemPrice' + subIndex" class="col-3 text-right">
                                <p class="p-s">{{ subItem.price }}</p>
                              </div>
                            </div>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </div>
            </tbody>
          </table>
          <!-- <CCard id="productAddOnActiveCard" style="background-color: #f6f6f6" class="adcard">
            <CCardBody id="productAddOnActiveBody">
              
              <div id="productAddOnActiveList" class="overflow-auto con-add" v-if="activeGroup.length > 0">
                <CRow id="productAddOnActiveGroupRow" v-for="(item, index) in activeGroup" :key="index">
                  <CCol :id="'productAddOnActiveGroupCol'+ index">
                    <CCard :id="'productAddOnActiveGroupCard'+ index" class="addon-body-active">
                      <CCardBody :id="'productAddOnActiveGroupBody'+ index">
                        <CRow :id="'productAddOnActiveGroupContentRow'+ index">
                          <CCol :id="'productAddOnActiveGroupImageCol'+ index" col="2">
                            <img
                              :id="'productAddOnActiveGroupImage'+ index"
                              src="/img/star_icon.png"
                              style="border-radius: 5px;"
                              class="img-fluid"
                            />
                          </CCol>
                          <CCol :id="'productAddOnActiveGroupInfoCol'+ index" col="10">
                            <tr :id="'productAddOnActiveGroupInfoRow'+ index">
                              <td :id="'productAddOnActiveGroupName' + index">
                                <p style="font-size: medium;">{{ item.groupActiveName }}</p>
                              </td>
                              <td :id="'productAddOnActiveGroupAction'+ index" class="text-right text-danger">
                                <img
                                  :id="'productAddOnActiveGroupActionIcon'+ index"
                                  src="/img/product/delete32.png"
                                  style="width: 25px;"
                                  v-if="isDeleteData"
                                  @click="confirmdeleteOptionGroup(item)"
                                  v-show="isEditData"
                                />
                              </td>
                            </tr>
                            <tr :id="'productAddOnActiveGroupDetailsRow'+ index">
                              <td :id="'productAddOnActiveGroupDetails'+ index" style="width: 70%; margin-bottom: 0rem;">
                                <CBadge :id="'productAddOnActiveGroupRequiredBadge'+ index" color="success" v-if="item.require">
                                  Required
                                </CBadge>
                                <CBadge :id="'productAddOnActiveGroupMinBadge'+ index" color="primary" v-if="item.minimum !== undefined">
                                  Min : <span>{{ item.minimum }}</span>
                                </CBadge>
                                <CBadge :id="'productAddOnActiveGroupMaxBadge'+ index" color="info" v-if="item.maximum !== undefined">
                                  Max : <span>{{ item.maximum }}</span>
                                </CBadge>
                              </td>
                              <td :id="'productAddOnActiveGroupTotals'+ index" class="text-right" style="margin-bottom: 0rem;">
                                {{ $t('totals') }} ({{ item.items.length }})
                              </td>
                            </tr>
                            <hr id="productAddOnActiveGroupDivider" class="hr-card-option" />
                            <tr
                              :id="'productAddOnActiveGroupItemsRow'+ index"
                              v-for="(subItem, subIndex) in item.items"
                              :key="subIndex"
                              style="border-top: 0px solid #ececec"
                            >
                              <td :id="'productAddOnActiveGroupItemName'+ subIndex" style="width: 90%">
                                <p class="p-s">
                                  <i class="fa-solid fa-circle" style="font-size: .3rem;"></i> {{ subItem.name }}
                                </p>
                              </td>
                              <td :id="'productAddOnActiveGroupItemPrice'+ subIndex" class="text-right">
                                <p class="p-s">{{ subItem.price }}</p>
                              </td>
                            </tr>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </div>

              <div id="productAddOnNoActiveOptions" class="overflow-auto con-add" style="max-height: 500px" v-else>
                <slot name="no-items-view">
                  <div id="productAddOnNoActiveItemsMessage" class="text-center my-5">
                    <h4 style="color: #ced2d8;">{{ $t('noItem') }}</h4>
                  </div>
                </slot>
              </div>
            </CCardBody>
          </CCard> -->
        </CCol>
      </CRow>
      <CRow id="productAddOnModalRow">
        <CModal id="productAddOnModal" color="danger" :title="$t('confirmDeletGroup')"
          :show.sync="deleteOptionGroupModal" centered>
          <h4 id="productAddOnModalHeader" class="mt-4 mb-4 text-center">{{ $t('dialigDeleteGroup') }}</h4>
          <template #footer>
            <CRow id="productAddOnModalFooterRow" class="justify-content-around col-md-12">
              <CCol id="productAddOnModalFooterDeleteCol" col="4">
                <CButton id="productAddOnModalFooterDeleteButton" v-if="loadingDeleteButton === false"
                  @click="deleteOptionGroup(itemDelete)" color="danger" block>
                  {{ $t('delete') }}
                </CButton>
                <CButton id="productAddOnModalFooterDeleteButtonLoading" v-else-if="loadingDeleteButton === true"
                  color="danger" block disabled>
                  <CSpinner color="white" size="sm" />
                </CButton>
              </CCol>
              <CCol id="productAddOnModalFooterCancelCol" col="4">
                <CButton id="productAddOnModalFooterCancelButton" v-if="loadingDeleteButton === false"
                  @click="deleteModal = false" color="light" block>
                  {{ $t('cancel') }}
                </CButton>
                <CButton id="productAddOnModalFooterCancelButtonLoading" v-else-if="loadingDeleteButton === true"
                  color="light" disabled block>
                  {{ $t('cancel') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
    </div>
  </div>
</template>

<script>
import local from "@/services/local";
import { mapGetters } from "vuex";
import permis from "@/util/permission";

export default {
  components: {},
  data() {
    return {
      loading: true,
      loadingButton: true,
      SKU: [],
      optionGroupActive: [],
      optionGroupFields: [],
      productOption: [],
      optionGroupListItem: [],
      optionGroup: [],
      optionGroupdata: [],
      AddOptionGroupModal: false,
      deleteOptionGroupModal: false,
      loadingDeleteButton: false,
      itemDelete: {}
    };
  },
  computed: {
    ...mapGetters(["shops", "users", "date"]),

    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.uid;
    },
    isEditData() {
      let path = '/product'
      const value = permis.findPermissionEdit('product', path)
      return value //&& this.editItem
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    optionGroupList() {
      let data = this.optionGroupdata;


      let objects = [];
      let dataActive = this.optionGroupActive;

      for (let i = 0; i < data.length; i++) {
        const object = data[i];
        let item = {};
        if (dataActive.some((active) => active.groupActiveObjectId === object.optionGroupObjectId)) {
          continue;
        }

        item = {
          name: object.optionGroupName,
          objectId: object.optionGroupObjectId,
          id: object.optionGroupId,
          selected: false, // Default value is false if condition is not met
          items: object.items,
          maximum: object.maximum,
          minimum: object.minimum,
          require: object.require,
        };


        objects.push(item);
      }

      return objects;
    },
    isDeleteData() {
      let path = "/product";
      return permis.findPermissionRemove("product", path);
    },
    activeGroup() {
      const object = [];
      //      maximum : this.productOption[i].maximum,
      // minimum : this.productOption[i].minimum,
      //         require :this.productOption[i].require,
      for (let i = 0; i < this.optionGroupActive.length; i++) {
        let item = {
          num: i + 1,
          groupActiveName: this.optionGroupActive[i].groupActiveName,
          groupActiveObjectId: this.optionGroupActive[i].groupActiveObjectId,
          groupActiveId: this.optionGroupActive[i].groupActiveId,
          action: "",
          activeObjectId: this.optionGroupActive[i].activeObjectId,
          activeId: this.optionGroupActive[i].activeId,
          items: this.optionGroupActive[i].items,
          maximum: this.optionGroupActive[i].maximum,
          minimum: this.optionGroupActive[i].minimum,
          require: this.optionGroupActive[i].require,
        };
        object.push(item);
      }

      return object;
    },
    productSKUdetail: {
      get() {
        return this.$store.getters.productSKUdetail
      },
      set() {

        return this.$store.getters.productSKUdetail
      }
    },
  },
  async created() {
    await this.getProductOption();
    await this.getOptionGroup();
    this.shop = this.shops.find((i) => i.objectId === this.shop)

  },
  mounted() { },
  methods: {
    confirmdeleteOptionGroup(item) {
      this.deleteOptionGroupModal = true
      this.itemDelete = item
    },
    async deleteOptionGroup() {
      try {
        this.shop = this.shops.find((i) => i.objectId === this.shopObjectId);
        const uid = `${localStorage.getItem('shopsUid')}`;
        let data = [{
          objectId: this.itemDelete.activeObjectId,
          id: this.itemDelete.activeObjectId,
          optionGroup: {
            objectId: this.itemDelete.groupActiveObjectId,
            name: this.itemDelete.groupActiveName,
            id: this.itemDelete.groupActiveId,
          },
          deleted_at: new Date(),
          productSKU: {
            SKUName: this.productSKUdetail.SKUName,
            SKUPrice: this.productSKUdetail.SKUPrice,
            id: this.productSKUdetail.id,
            objectId: this.productSKUdetail.objectId,
          },
          uid: uid,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
        }];
        const headers = { shopObjectId: this.shopObjectId };
        this.loading = false;

        const response = await local({
          method: 'post',
          url: '/api/V1.0/' + uid + '/option/activegroup/update',
          data: data,
          headers: headers,
        });

        // กระทำหลังจาก API ทำงานเสร็จ
        setTimeout(() => {
          this.loading = true;
          this.loadingDeleteButton = false;
          this.AddOptionGroupModal = false;
          this.deleteOptionGroupModal = false;
          this.getProductOption();
          this.getOptionGroup();
        }, 1000);
      }
      catch (error) {
        console.log(error);
      }
    },
    onSelectedAutoCompleteEvent(item) {
      if (item.selected == true) {
        this.selectedItems.push(
          {
            name: item.name,
            objectId: item.objectId,
            id: item.id
          }
        );
      } else {
        // ถ้า item ถูกยกเลิกการเลือก
        this.selectedItems = this.selectedItems.filter(
          (x) => x.objectId !== item.objectId
        );
      }
    },
    async getProductOption() {
      try {
        this.loadingButton = false;
        const uid = this.uid;
        // const skuObjectId = this.SKU.objectId;
        const skuObjectId = this.$route.params.productSKUObjectId;

        let params = { skuObjectId: skuObjectId };

        const res = await local.get(
          "/api/v1.0/" + uid + "/productsku/getproductoption",
          { params }
        );

        this.optionGroupActive = [];
        if (res.data.data.deleted_at !== undefined) {
          this.productOption = res.data.data.filter(
            (item) => item.deleted_at === undefined
          );
        } else {
          this.productOption = res.data.data;
        }
        for (let i = 0; i < this.productOption.length; i++) {
          let items = [];
          for (let j = 0; j < this.productOption[i].OptionGroup.items.length; j++) {

            let item = {
              name: this.productOption[i].OptionGroup.items[j].optionItem.name,
              price: this.productOption[i].OptionGroup.items[j].optionItem.price,
            };

            items.push(item);
          }
          let maximum = 0
          let minimum = 0

          if (this.productOption[i].maximum !== undefined) {
            maximum = this.productOption[i].maximum
          }

          if (this.productOption[i].minimum !== undefined) {
            minimum = this.productOption[i].minimum
          }

          let item = {
            num: i + 1,
            groupActiveName: this.productOption[i].OptionGroup.name,
            groupActiveObjectId: this.productOption[i].OptionGroup.objectId,
            groupActiveId: this.productOption[i].OptionGroup.id,
            activeObjectId: this.productOption[i].objectId,
            activeId: this.productOption[i].id,
            maximum: maximum,
            minimum: minimum,
            require: this.productOption[i].require,
            items: items,
          };
          this.optionGroupActive.push(item);
        }
        this.loading = true;
      } catch (error) {
        console.error(error);
      }
    },
    async getOptionGroup() {
      try {
        this.loading = false;
        const uid = this.uid;
        const shopObjectId = this.shopObjectId;
        let params = { shopObjectId: shopObjectId };
        this.optionGroupdata = [];
        await local
          .get("/api/V1.0/" + uid + "/option/group/get", { params })
          .then((res) => {
            if (res.data.data.deleted_at !== undefined) {
              this.optionGroup = res.data.data.filter(
                (item) => item.deleted_at === undefined
              );
            } else {
              this.optionGroup = res.data.data;
            }



            for (let i = 0; i < this.optionGroup.length; i++) {
              let maximum = 0
              let minimum = 0
              if (this.optionGroup[i].maximum !== undefined) {
                maximum = this.optionGroup[i].maximum
              }

              if (this.optionGroup[i].minimum !== undefined) {
                minimum = this.optionGroup[i].minimum
              }

              let item = {
                num: i + 1,
                optionGroupName: this.optionGroup[i].name,
                optionGroupObjectId: this.optionGroup[i].objectId,
                optionGroupId: this.optionGroup[i].id,
                maximum: maximum,
                minimum: minimum,
                require: this.optionGroup[i].require,
                items: this.optionGroup[i].items,
              };
              this.optionGroupdata.push(item);
            }

            this.loadingButton = true;
          });
      } catch (error) {
        console.log(error);
      }
    },
    async onCheckboxChange(item) {
      let option = {
        name: item.name,
        objectId: item.objectId,
        id: item.id
      }
      try {
        this.shop = this.shops.find((i) => i.objectId === this.shopObjectId);
        const uid = `${localStorage.getItem('shopsUid')}`;


        let dataSelectd = {
          optionGroup: option,
          productSKU: {
            SKUName: this.productSKUdetail.SKUName,
            SKUPrice: this.productSKUdetail.SKUPrice,
            id: this.productSKUdetail.id,
            objectId: this.productSKUdetail.objectId,
          },
          uid: uid,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
        }


        const headers = { shopObjectId: this.shopObjectId };
        this.loading = false;

        await local({
          method: 'post',
          url: '/api/V1.0/' + uid + '/option/activegroup/update',
          data: [dataSelectd],
          headers: headers,
        }).then((response) => {

          setTimeout(() => {
            this.loading = true;
            this.loadingButtonValidatePRU = false;
            // this.selectedItems = [];
            this.getOptionGroup();
            this.getProductOption();
          }, 1000)
        })
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.add-on-card {

  border-color: #29b289;
}

.adcard {
  /* min-height: 600px;
    max-height: 600px; */
}

.hr-card-option {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px;
  border-top: 1px solid rgba(0, 0, 21, 0.2);
}

.p-s {
  margin-bottom: 0rem;
  font-size: smaller;
}

.addon-body {
  border: 1px solid #29b289;
  border-radius: 5px;
  padding: 0rem;
  /* min-height: 120px; */
}

.addon-body-active {
  border: 2px solid #F6F6F6;
  box-shadow: rgba(0, 0, 21, 0.075) 0px 0.125rem 0.25rem;
  border-radius: 5px;
  padding: 0rem;
  /* min-height: 120px; */
}


.con-add {
  min-height: 500px;
  max-height: 500px;
}

@media(min-width: 1400px) {
  .con-add {
    min-height: 650px;
    max-height: 650px;
  }

}

@media(min-width: 1200px) {
  .con-add {
    min-height: 650px;
    max-height: 650px;
  }
}

@media(min-width: 1000px) {
  .con-add {
    min-height: 550px;
    max-height: 550px;
  }

}
.aohover:hover {
    -webkit-box-shadow: 0 6px 10px 0 rgb(0 0 0 / 16%);
    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 16%);
    cursor: pointer;
}
</style>
